import React, { useState } from "react";
import { connect } from "react-redux";
import { updateOrderStatus } from "../../store/actions/Orders";

const OrderStatus = ({ order, updateOrderStatus }) => {
  const orderId = order.id;
  const currentStatus = order.status;
  const [status, setStatus] = useState(currentStatus);

  const handleSubmit = (evt) => {
    evt.preventDefault();
    updateOrderStatus({ status, id: orderId });
  };
  return (
    <div className="container">
      <form className="row" onSubmit={handleSubmit}>
        <div className="col-8 p-0">
          <label htmlFor="inputState" className="sr-only">
            Status
          </label>
          <select
            defaultValue={currentStatus}
            onChange={(e) => setStatus(e.target.value)}
            id="inputState"
            className="form-control"
          >
            <option>Submitted</option>
            <option>Confirmed</option>
            <option>Processing</option>
            <option>Complete</option>
          </select>
        </div>
        <div className="col-4">
          <input type="submit" value="Submit" className="btn btn-success" />
        </div>
      </form>
    </div>
  );
};

export default connect(null, { updateOrderStatus })(OrderStatus);
