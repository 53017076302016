import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reducers from "./store/reducers";
import { Provider } from "react-redux";
import { createStore, compose, applyMiddleware } from "redux";
import { history } from "./history";
import createSagaMiddleware from "redux-saga";
import { routerMiddleware, connectRouter } from "connected-react-router";
import rootSaga from "./store/sagas";
import * as serviceWorker from "./serviceWorker";

const sagaMiddleware = createSagaMiddleware();

const store = createStore(
  connectRouter(history)(reducers),
  {},
  compose(applyMiddleware(sagaMiddleware, routerMiddleware(history)))
);

sagaMiddleware.run(rootSaga);

ReactDOM.render(
  <Provider store={store}>
    <App history={history} />
  </Provider>,
  document.getElementById("root")
);

serviceWorker.unregister();
