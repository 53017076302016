import { SIGNIN_USER_SUCCESS } from "../actions/types";

const INIT_STATE = {
  companyUsers: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SIGNIN_USER_SUCCESS: {
      return {
        ...state,
        companyUsers: action.payload[3],
      };
    }
    default:
      return state;
  }
};
