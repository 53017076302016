import {
  all,
  call,
  fork,
  put,
  takeEvery,
  take,
  cancelled,
  takeLatest,
  race,
} from "redux-saga/effects";

import {
  SIGNIN_USER,
  GETTING_USER_PROFILE,
  CHECK_USER_AUTHENTICATED,
  SIGNOUT_USER,
} from "../actions/types";

import { eventChannel } from "redux-saga";

import { ordersCollectionWatch } from "./Orders";

import { auth, db } from "../../config/fbConfig";

import { userNotAuthenticated, userSignOutSuccess } from "../actions/Auth";

const users = db.collection("users");

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////// Sign In Email Password ////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const signInEmailPasswordRequest = async (email, password) =>
  await auth
    .signInWithEmailAndPassword(email, password)
    .then((authUser) => {
      return { authUser };
    })
    .catch((error) => {
      return { error };
    });

function* signInEmailPassword({ payload }) {
  const { email, password } = payload;
  const { authUser, error } = yield call(() =>
    signInEmailPasswordRequest(email, password)
  );
  if (authUser) {
    yield fork(userCollectionWatch, authUser.user);
  } else {
    //TODO: Error Handling
    console.log(error);
    yield put(userNotAuthenticated());
  }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////// Get User Profile /////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export function* userCollectionWatch(user) {
  const userCollectionChannel = eventChannel((emit) => {
    const unsubcscribeUserCollectionData = users
      .doc(`${user.uid}`)
      .onSnapshot(function (doc) {
        if (doc) {
          emit(doc.data());
        } else {
          doc = { exists: false };
          emit({ doc });
        }
      });
    return unsubcscribeUserCollectionData;
  });
  try {
    while (true) {
      const { userSignOut, userCollectionData } = yield race({
        userSignOut: take(SIGNOUT_USER),
        userCollectionData: take(userCollectionChannel),
      });

      if (userSignOut) {
        userCollectionChannel.close();
      } else {
        yield fork(ordersCollectionWatch, userCollectionData, user);
      }
    }
  } catch (error) {
    //TODO: Error Handling
    console.log(error);
  } finally {
    if (yield cancelled()) userCollectionChannel.close();
  }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////// Checking For Auth User ///////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const userAuthChannel = eventChannel((emit) => {
  const unsubscribeAuth = auth.onAuthStateChanged((user) => {
    if (user) {
      user.exists = true;
      emit({ user });
    } else {
      user = { exists: false };
      emit({ user });
    }
  });
  return unsubscribeAuth;
});

export function* userAuthWatch() {
  try {
    while (true) {
      const user = yield take(userAuthChannel);
      if (user.user.exists === true) {
        yield fork(userCollectionWatch, user.user);
        userAuthChannel.close(); //Unregistering as we dont need to listen continuously
      } else {
        yield put(userNotAuthenticated());
        userAuthChannel.close();
      }
    }
  } catch (error) {
    //TODO: Error Handling
    console.log(error);
  } finally {
    // unregister listener if the saga was cancelled
    if (yield cancelled()) userAuthChannel.close();
  }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////// Sign Out User //////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const signOutRequest = async () =>
  await auth
    .signOut()
    .then((authUser) => authUser)
    .catch((error) => error);

function* signOut() {
  try {
    const signOutUser = yield call(signOutRequest);
    if (signOutUser?.message) {
      console.log(signOutUser.message);
    } else {
      yield put(userSignOutSuccess());
    }
  } catch (error) {
    console.log(error);
  }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////// Action Creators For Root Saga ////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export function* signInUser() {
  yield takeLatest(SIGNIN_USER, signInEmailPassword);
}
export function* getUserProfile() {
  yield takeLatest(GETTING_USER_PROFILE, userCollectionWatch);
}

export function* getUserAuth() {
  yield takeLatest(CHECK_USER_AUTHENTICATED, userAuthWatch);
}

export function* signOutUser() {
  yield takeEvery(SIGNOUT_USER, signOut);
}

export default function* rootSaga() {
  yield all([
    fork(signInUser),
    fork(getUserProfile),
    fork(getUserAuth),
    fork(signOutUser),
  ]);
}
