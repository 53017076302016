import { SIGNIN_USER_SUCCESS } from "../actions/types";

const INIT_STATE = {
  userOrders: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SIGNIN_USER_SUCCESS: {
      return {
        ...state,
        userOrders: action.payload[2],
      };
    }
    default:
      return state;
  }
};
