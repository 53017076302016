import React from "react";
import { connect } from "react-redux";
import moment from "moment";
import {
  Col,
  Row,
  Button,
  Card,
  CardTitle,
  CardText,
  CardHeader,
  Badge,
} from "reactstrap";

const OrderDetails = ({ match, history, userOrders }) => {
  const id = match.params.id;
  let filteredOrder = userOrders.filter((order) => order.id === id);
  const order = filteredOrder[0];
  if (order) {
    return (
      <div className="container">
        <Row>
          <Col sm={12}>
            <Button
              onClick={history.goBack}
              className="btn btn-sm btn-secondary"
            >
              <i className="fas fa-arrow-left"></i> Back
            </Button>
          </Col>
        </Row>
        <Row className="my-3">
          <Col md={6} className="mb-3 mb-md-0">
            <Card body className="box-shadow">
              <CardTitle className="d-flex align-items-center justify-content-between">
                <div>
                  <span className="title text-dark-grey">{order.petName}</span>
                </div>
                <div>
                  {order.orderType === "Private" ? (
                    <Badge color="success">Private</Badge>
                  ) : (
                    <Badge color="secondary">Communal</Badge>
                  )}
                </div>
              </CardTitle>
              <Row className="mb-3">
                <Col md={12}>
                  <CardText className="text-dark-grey">
                    <small>Pet Type:</small> <strong>{order.petType}</strong>
                  </CardText>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md={12}>
                  <CardText className="text-dark-grey">
                    <small>Pet Breed:</small>{" "}
                    <strong>
                      {order.petBreed === "" ? (
                        "not specified"
                      ) : (
                        <span>{order.petBreed}</span>
                      )}
                    </strong>
                  </CardText>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md={12}>
                  <CardText className="text-dark-grey">
                    <small>Submitted On:</small>{" "}
                    <strong>
                      {moment(order.createdAt.toDate()).format(
                        "MM/DD/YY h:mm a"
                      )}
                    </strong>
                  </CardText>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col md={3} className="mb-3 mb-md-0">
            <Card body className="text-white bg-secondary h-100 box-shadow">
              <CardHeader className="p-0">
                <CardTitle className="text-light-grey">
                  Approximate Weight
                </CardTitle>
              </CardHeader>
              <div className="text-center">
                <span className="jumbo">{order.petWeight}</span>
                <small>lbs</small>
              </div>
            </Card>
          </Col>
          <Col md={3} className="mb-md-0">
            <Card
              body
              className={
                "text-white align-items-center justify-content-center h-100 box-shadow bg-" +
                order.status
              }
            >
              <div className="text-center">
                <span className="mini-jumbo">{order.status}</span>
              </div>
            </Card>
          </Col>
        </Row>

        <Row className="my-3">
          <Col md={4} className="mb-3 mb-md-0">
            <Card body className="box-shadow">
              <Row>
                <Col md={12}>
                  {order.disclaimer === true ? (
                    <div className="d-flex align-items-center">
                      <div className="mr-2">
                        <span className="text-success mini-jumbo">
                          <i className="fas fa-check-circle"></i>
                        </span>
                      </div>
                      <div>
                        <p className="mb-0 text-dark-grey">Disclaimer Signed</p>
                      </div>
                    </div>
                  ) : (
                    <div className="d-flex align-items-center">
                      <div className="mr-2">
                        <span className="text-danger mini-jumbo">
                          <i className="far fa-times-circle"></i>
                        </span>
                      </div>
                      <div>
                        <p className="mb-0 text-dark-grey">
                          Disclaimer Missing
                        </p>
                      </div>
                    </div>
                  )}
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  {order.authorization === true ? (
                    <div className="d-flex align-items-center">
                      <div className="mr-2">
                        <span className="text-success mini-jumbo">
                          <i className="fas fa-check-circle"></i>
                        </span>
                      </div>
                      <div>
                        <p className="mb-0 text-dark-grey">
                          Authorization Signed
                        </p>
                      </div>
                    </div>
                  ) : (
                    <div className="d-flex align-items-center">
                      <div className="mr-2">
                        <span className="text-danger mini-jumbo">
                          <i className="far fa-times-circle"></i>
                        </span>
                      </div>
                      <div>
                        <p className="mb-0 text-dark-grey">
                          Authorization Missing
                        </p>
                      </div>
                    </div>
                  )}
                </Col>
              </Row>
            </Card>
          </Col>
          <Col md={4} className="mb-3 mb-md-0">
            <Card body className="box-shadow">
              <Row>
                <Col md={12}>
                  {order.pawPrint === "Yes" ? (
                    <div className="d-flex align-items-center">
                      <div className="mr-2">
                        <span className="text-success mini-jumbo">
                          <i className="fas fa-check-circle"></i>
                        </span>
                      </div>
                      <div>
                        <p className="mb-0 text-dark-grey">
                          Paw Print Qty: <strong>{order.pawPrintQty}</strong>
                        </p>
                      </div>
                    </div>
                  ) : (
                    <div className="d-flex align-items-center">
                      <div className="mr-2">
                        <span className="text-danger mini-jumbo">
                          <i className="far fa-times-circle"></i>
                        </span>
                      </div>
                      <div>
                        <p className="mb-0 text-dark-grey">Paw Print</p>
                      </div>
                    </div>
                  )}
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  {order.urnType === "Standard" ? (
                    <div className="d-flex align-items-center">
                      <div className="mr-2">
                        <span className="text-success mini-jumbo">
                          <i className="fas fa-check-circle"></i>
                        </span>
                      </div>
                      <div>
                        <p className="mb-0 text-dark-grey">Standard Urn</p>
                      </div>
                    </div>
                  ) : (
                    <div className="d-flex align-items-center">
                      <div className="mr-2">
                        <span className="text-success mini-jumbo">
                          <i className="far fa-check-circle"></i>
                        </span>
                      </div>
                      <div>
                        <p className="mb-0 text-dark-grey">
                          Specialty Rosewood: <strong>{order.urnSize}</strong>
                        </p>
                      </div>
                    </div>
                  )}
                </Col>
              </Row>
            </Card>
          </Col>
          <Col md={4} className="mb-3 mb-md-0">
            <Card body className="box-shadow h-100">
              <CardTitle className="text-grey">
                Additional Instructions
              </CardTitle>
              <CardText className="text-dark-grey">{order.comment}</CardText>
            </Card>
          </Col>
        </Row>

        <Row className="my-3">
          <Col md={12}>
            <Card body className="box-shadow h-100">
              <Row>
                <Col md={6}>
                  <CardText className="text-dark-grey">
                    <small>Owner Name:</small>{" "}
                    <strong>{order.ownerName}</strong>
                  </CardText>
                  <CardText className="text-dark-grey">
                    <small>Owner Address:</small>{" "}
                    <strong>{order.ownerAddress}</strong>
                  </CardText>
                  <CardText className="text-dark-grey">
                    <small>Owner Phone:</small>{" "}
                    <strong>{order.ownerPhone}</strong>
                  </CardText>
                  <CardText className="text-dark-grey">
                    <small>Owner Email:</small>{" "}
                    <strong>{order.ownerEmail}</strong>
                  </CardText>
                </Col>
                <Col md={6}>
                  <CardText className="text-dark-grey">
                    <small>Submitted By:</small>{" "}
                    <strong>{order.submittedBy}</strong>
                  </CardText>
                  <CardText className="text-dark-grey">
                    <small>Authorized By:</small>{" "}
                    <strong>{order.authorizedRep}</strong>
                  </CardText>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </div>
    );
  } else {
    return (
      <div className="d-flex align-items-center justify-content-between p-3 my-3 bg-white rounded box-shadow">
        <p>Loading order...</p>
      </div>
    );
  }
};

const mapStateToProps = ({ auth, orders }) => {
  const { authUser } = auth;
  const { userOrders } = orders;
  return {
    authUser,
    userOrders,
  };
};

export default connect(mapStateToProps)(OrderDetails);
