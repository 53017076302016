import React from "react";
import UserSummary from "./UserSummary";
import { Link } from "react-router-dom";
import * as routes from "../../Router/constants/routes";

const UsersList = ({ users }) => {
  console.log(users);
  return (
    <div className="orders-list my-3 pt-3 pb-4 pl-3 pr-3 bg-white rounded box-shadow">
      <h6 className="border-bottom border-gray pb-2 mb-0">All Users</h6>

      {users &&
        users.map((user) => {
          return (
            <Link
              to={{
                pathname: `${routes.USER_DETAILS}/${user.id}`,
                state: {
                  user: user,
                },
              }}
              key={user.id}
              className="user-summary"
            >
              <UserSummary key={user.id} user={user} />
            </Link>
          );
        })}
    </div>
  );
};

export default UsersList;
