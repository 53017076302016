import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";

// Development Firebase configuration
// var devfirebaseConfig = {
//   apiKey: "AIzaSyBfC1cBMcSdEhdph0StxoPovo0oQ7BA2jc",
//   authDomain: "legacy-dev-e179c.firebaseapp.com",
//   databaseURL: "https://legacy-dev-e179c.firebaseio.com",
//   projectId: "legacy-dev-e179c",
//   storageBucket: "legacy-dev-e179c.appspot.com",
//   messagingSenderId: "406514729020",
//   appId: "1:406514729020:web:f3e7601cb2c35f42560339",
//   measurementId: "G-XRQRWJ3HZJ",
// };

// Production Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyB4wkkNwZCb9FVYK3j7Q-dRrmLKvQv1qLI",
  authDomain: "legacy-prod-d7149.firebaseapp.com",
  databaseURL: "https://legacy-prod-d7149.firebaseio.com",
  projectId: "legacy-prod-d7149",
  storageBucket: "legacy-prod-d7149.appspot.com",
  messagingSenderId: "1096300979541",
  appId: "1:1096300979541:web:828e2de79400700bcf7bdc",
  measurementId: "G-5TX65B6W23"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();
export const db = firebase.firestore();

export default firebase;
