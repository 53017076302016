import { combineReducers } from "redux";
import authReducer from "./Auth";
import ordersReducer from "./Orders";
import usersReducer from "./Users";

const rootReducer = combineReducers({
  auth: authReducer,
  orders: ordersReducer,
  users: usersReducer,
});

export default rootReducer;
