import React from "react";
import moment from "moment";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import OrderStatus from "./OrderStatus";

const OrderSummary = ({ order, profile, userOrders }) => {
  return (
    <div className="container text-muted pb-2 pt-2">
      <div className={`row mb-0 border align-items-center rounded box-shadow`}>
        <div
          className={`col-12 p-4 col-md-8 mb-4 mb-md-0 rounded left-border border-${order.status}`}
        >
          <Link to={"/order/" + order.id} className="order-summary">
            <div className="row align-items-center">
              <div className="col-sm-6 col-md-4">
                <strong className="title text-gray-dark">
                  {order.petName}
                </strong>
              </div>
              <div className="col-sm-3 col-md-4">
                <small>
                  <strong>Type: </strong>
                  {order.petType}
                </small>
              </div>
              <div className="col-sm-3 col-md-4">
                <small>
                  <strong>Submitted: </strong>
                  {moment(order.createdAt.toDate()).format("MM/DD/YY h:mm a")}
                </small>
              </div>
            </div>
          </Link>
        </div>
        <div className="col-12 col-md-4 mb-3 mb-md-0 text-md-right text-center">
          {profile.makeAdmin ? (
            <OrderStatus order={order} />
          ) : (
            <h4>
              <span className={"badge badge-" + order.status}>
                {order.status}
              </span>
            </h4>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ auth, orders }) => {
  const { profile } = auth;
  const { userOrders } = orders;
  return {
    profile,
    userOrders,
  };
};

export default withRouter(connect(mapStateToProps)(OrderSummary));
