import React, { Component } from "react";
import UsersList from "./UsersList";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import * as routes from "../../Router/constants/routes";

class UserDashboard extends Component {
  render() {
    const { companyUsers } = this.props;

    return (
      <div className="dashboard">
        <div className="d-flex align-items-center justify-content-end">
          <Link to={routes.CREATE_USER} className="btn btn-success">
            New User
          </Link>
        </div>
        <UsersList users={companyUsers} />
      </div>
    );
  }
}

const mapStateToProps = ({ users }) => {
  const { companyUsers } = users;
  return {
    companyUsers,
  };
};

export default connect(mapStateToProps)(UserDashboard);
