import React, { Component } from "react";
import firebase from "firebase/app";
import "firebase/functions";
import {
  Col,
  Row,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  FormText,
} from "reactstrap";

class CreateUser extends Component {
  state = {
    email: "",
    password: "",
    displayName: "",
    error: null,
    success: null,
  };
  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
    });
  };
  handleSubmit = (e) => {
    e.preventDefault();
    e.target.reset();
    this.setState({ error: null });
    const newUser = this.state;
    const createUser = firebase.functions().httpsCallable("createUser");
    createUser(newUser)
      .then((result) => {
        if (result.data.errorInfo?.message) {
          this.setState({ error: result.data.errorInfo.message });
        } else {
          this.setState({ success: result.data });
        }
      })
      .catch((error) => console.log(error));
  };
  render() {
    return (
      <div className="col-md-6 offset-md-3 my-3 pt-3 pb-4 pl-3 pr-3 bg-white rounded box-shadow">
        <h6 className="border-bottom border-gray pb-2 mb-4">
          Create New User Account
        </h6>
        <Form onSubmit={this.handleSubmit} className="create-form">
          <Row form>
            <Col md={12}>
              <FormGroup>
                <Label for="email">Email</Label>
                <Input
                  onChange={this.handleChange}
                  type="email"
                  name="email"
                  id="email"
                />
                <FormFeedback valid>Good!</FormFeedback>
              </FormGroup>
            </Col>
          </Row>
          <Row form>
            <Col md={12}>
              <FormGroup>
                <Label for="password">Password</Label>
                <Input
                  onChange={this.handleChange}
                  type="password"
                  name="password"
                  id="password"
                />
              </FormGroup>
            </Col>
          </Row>
          <Row form>
            <Col md={12}>
              <FormGroup>
                <Label for="displayName">Name</Label>
                <Input
                  onChange={this.handleChange}
                  type="text"
                  name="displayName"
                  id="displayName"
                />
                <FormText>
                  Use company name for new clients. Use fullname for new users.
                </FormText>
              </FormGroup>
            </Col>
          </Row>
          <Button>Create Account</Button>
        </Form>
        {this.state.error && (
          <small className="messages text-danger">{this.state.error}</small>
        )}
        {this.state.success && (
          <small className="messages text-success">{this.state.success}</small>
        )}
      </div>
    );
  }
}

export default CreateUser;
