import {
  SIGNIN_USER_SUCCESS,
  SIGNIN_USER_FAILURE,
  SIGNIN_USER,
  CHECK_USER_AUTHENTICATED,
  USER_AUTHENTICATED_FAILURE,
  SIGNOUT_USER_SUCCESS,
} from "../actions/types";

const INIT_STATE = {
  authUser: null,
  authError: null,
  profile: null,
  loading: false,
  initializing: true,
  checkingAuthStatus: true,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SIGNIN_USER: {
      return {
        ...state,
        loading: true,
      };
    }
    case SIGNIN_USER_SUCCESS: {
      return {
        ...state,
        authUser: action.payload[0],
        profile: action.payload[1],
        loading: false,
        initializing: false,
      };
    }
    case SIGNIN_USER_FAILURE: {
      return {
        ...state,
        authError: action.payload,
        loading: false,
      };
    }
    case CHECK_USER_AUTHENTICATED: {
      return {
        ...state,
        initializing: true,
      };
    }
    case USER_AUTHENTICATED_FAILURE: {
      return {
        ...state,
        loading: false,
        initializing: false,
        authUser: null,
      };
    }
    case SIGNOUT_USER_SUCCESS: {
      return {
        ...state,
        loading: false,
        authUser: null,
      };
    }
    default:
      return state;
  }
};
