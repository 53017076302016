import React, { Component } from "react";
import { connect } from "react-redux";
import CreateOrderOne from "./CreateOrderOne";
import CreateOrderTwo from "./CreateOrderTwo";
import CreateOrderThree from "./CreateOrderThree";
import { Form } from "reactstrap";
import { creatingOrder } from "../../store/actions/Orders";
import * as routes from "../../Router/constants/routes";
import {
  validateEmpty,
  validateEmail,
  validatePhoneNumber,
  validateSelect,
  validateWeight,
} from "../../utils/Helpers";

class CreateOrder extends Component {
  state = {
    step: 1,
    petName: "",
    petType: "",
    petBreed: "",
    petWeight: "",
    ownerName: "",
    ownerAddress: "",
    ownerPhone: "",
    ownerEmail: "",
    orderType: "",
    authorization: false,
    disclaimer: false,
    authorizedRep: "",
    pawPrint: "",
    pawPrintQty: "",
    urnType: "",
    urnSize: "",
    comment: "",
    petNameError: false,
    petTypeError: false,
    petWeightError: false,
    ownerNameError: false,
    ownerAddressError: false,
    ownerPhoneError: false,
    ownerEmailError: false,
    orderTypeError: false,
    authorizationError: false,
    disclaimerError: false,
    authorizedRepError: false,
    pawPrintError: false,
    pawPrintQtyError: false,
    urnTypeError: false,
    urnSizeError: false,
  };

  nextStep = () => {
    const { step } = this.state;

    this.setState({
      step: step + 1,
    });
  };

  prevStep = () => {
    const { step } = this.state;
    this.setState({
      step: step - 1,
    });
  };

  handleChange = (input) => (e) => {
    if (input === "ownerEmail") {
      this.setState({
        [input]: e.target.value,
        [`${input}Error`]: validateEmail(e.target.value),
      });
    } else if (input === "ownerPhone") {
      this.setState({
        [input]: e.target.value,
        [`${input}Error`]: validatePhoneNumber(e.target.value),
      });
    } else if (
      input === "orderType" ||
      input === "pawPrint" ||
      input === "urnType" ||
      input === "urnSize"
    ) {
      this.setState({
        [input]: e.target.value,
        [`${input}Error`]: validateSelect(e.target.value),
      });
    } else if (input === "petWeight" || input === "pawPrintQty") {
      this.setState({
        [input]: e.target.value,
        [`${input}Error`]: validateWeight(e.target.value),
      });
    } else {
      this.setState({
        [input]: e.target.value,
        [`${input}Error`]: validateEmpty(e.target.value),
      });
    }
  };

  handleCheckboxChange = (changeEvent) => {
    const { name } = changeEvent.target;
    this.setState({
      [name]: changeEvent.target.checked,
      [`${name}Error`]: !changeEvent.target.checked,
    });
  };

  showStep = () => {
    const {
      step,
      petName,
      petType,
      petBreed,
      petWeight,
      ownerName,
      ownerAddress,
      ownerPhone,
      ownerEmail,
      orderType,
      authorization,
      disclaimer,
      authorizedRep,
      pawPrint,
      pawPrintQty,
      urnType,
      urnSize,
      comment,
      petNameError,
      petTypeError,
      petWeightError,
      ownerNameError,
      ownerAddressError,
      ownerPhoneError,
      ownerEmailError,
      orderTypeError,
      authorizationError,
      disclaimerError,
      authorizedRepError,
      pawPrintError,
      pawPrintQtyError,
      urnTypeError,
      urnSizeError,
    } = this.state;

    if (step === 1)
      return (
        <CreateOrderOne
          nextStep={this.nextStep}
          handleChange={this.handleChange}
          handleCheckboxChange={this.handleCheckboxChange}
          petName={petName}
          petType={petType}
          petBreed={petBreed}
          petWeight={petWeight}
          ownerName={ownerName}
          ownerAddress={ownerAddress}
          ownerPhone={ownerPhone}
          ownerEmail={ownerEmail}
          orderType={orderType}
          authorization={authorization}
          disclaimer={disclaimer}
          authorizedRep={authorizedRep}
          petNameError={petNameError}
          petTypeError={petTypeError}
          petWeightError={petWeightError}
          ownerNameError={ownerNameError}
          ownerAddressError={ownerAddressError}
          ownerPhoneError={ownerPhoneError}
          ownerEmailError={ownerEmailError}
          orderTypeError={orderTypeError}
          authorizationError={authorizationError}
          disclaimerError={disclaimerError}
          authorizedRepError={authorizedRepError}
        />
      );
    if (step === 2)
      return (
        <CreateOrderTwo
          nextStep={this.nextStep}
          prevStep={this.prevStep}
          handleChange={this.handleChange}
          handleCheckboxChange={this.handleCheckboxChange}
          pawPrint={pawPrint}
          pawPrintQty={pawPrintQty}
          urnType={urnType}
          urnSize={urnSize}
          comment={comment}
          pawPrintError={pawPrintError}
          pawPrintQtyError={pawPrintQtyError}
          urnTypeError={urnTypeError}
          urnSizeError={urnSizeError}
        />
      );
    if (step === 3)
      return (
        <CreateOrderThree
          petName={petName}
          petType={petType}
          petBreed={petBreed}
          petWeight={petWeight}
          ownerName={ownerName}
          ownerAddress={ownerAddress}
          ownerPhone={ownerPhone}
          ownerEmail={ownerEmail}
          orderType={orderType}
          authorization={authorization}
          disclaimer={disclaimer}
          authorizedRep={authorizedRep}
          pawPrint={pawPrint}
          pawPrintQty={pawPrintQty}
          urnType={urnType}
          urnSize={urnSize}
          comment={comment}
          prevStep={this.prevStep}
        />
      );
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.creatingOrder(this.state);
    this.props.history.push(routes.DASHBOARD);
  };

  render() {
    const { step } = this.state;
    return (
      <div className="container col-md-12 mb-5 p-4 bg-white rounded box-shadow">
        <div className="d-flex mb-3 border-bottom border-gray align-items-center justify-content-between">
          <div>
            <h5>Create New Order</h5>
          </div>
          <div className="text-muted pb-2">
            <small>Step {step} of 3</small>
          </div>
        </div>
        <Form onSubmit={this.handleSubmit} className="create-order-form">
          {this.showStep()}
        </Form>
        <div className="text-danger"></div>
      </div>
    );
  }
}

const mapStateToProps = ({ auth }) => {
  const { authUser } = auth;
  return {
    authUser,
  };
};

export default connect(mapStateToProps, { creatingOrder })(CreateOrder);
