import React from "react";
import { Button, Form } from "reactstrap";

const UserDetails = ({ location }) => {
  const { user } = location.state;

  const handleSubmit = (e) => {
    e.preventDefault();

    // TODO: make function to make a user an admin from sagas

    // const userId = id;
    // dispatch(makeAdmin(user.id));
  };

  if (user) {
    return (
      <div className="d-flex align-items-center justify-content-between p-3 my-3 bg-white rounded box-shadow">
        <h3 className="title">{user.displayName}</h3>
        <p>{user.email}</p>
        <p>{user.id}</p>
        <div className="m-4">
          <Form onSubmit={handleSubmit}>
            <Button>Make Admin</Button>
          </Form>
          <small className="messages"></small>
        </div>
      </div>
    );
  } else {
    return (
      <div className="d-flex align-items-center justify-content-between p-3 my-3 bg-white rounded box-shadow">
        <p>Loading user...</p>
      </div>
    );
  }
};

export default UserDetails;
