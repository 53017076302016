import React from "react"
import OrderSummary from "./OrderSummary"
import { Link } from "react-router-dom"
import * as routes from "../../Router/constants/routes"

const OrdersList = ({ orders }) => {
  return (
    <div className="orders-list my-3 pt-3 pb-4 pl-3 pr-3 bg-white rounded">
      <div className="d-flex align-items-center justify-content-between">
        <div>
          <h5>Cremation Log</h5>
        </div>
        <div className="pb-2 mb-2">
          <Link to={routes.CREATE_ORDER} className="btn btn-success">
            <i className="fas fa-plus"></i> New Order
          </Link>
        </div>
      </div>
      {orders &&
        orders.map((order) => {
          return <OrderSummary key={order.id} order={order} />
        })}
    </div>
  )
}

export default OrdersList
