import React, { Component } from "react";
import {
  Col,
  CustomInput,
  Row,
  Button,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import { Link } from "react-router-dom";

class CreateOrderOne extends Component {
  continue = (e) => {
    e.preventDefault();
    this.props.nextStep();
  };

  _formValidated = () => {
    if (
      !this.props.petNameError &&
      this.props.petName.length &&
      !this.props.petTypeError &&
      this.props.petType.length &&
      !this.props.petWeightError &&
      this.props.petWeight.length &&
      !this.props.ownerNameError &&
      this.props.ownerName.length &&
      !this.props.ownerAddressError &&
      this.props.ownerAddress.length &&
      !this.props.ownerPhoneError &&
      this.props.ownerPhone.length &&
      !this.props.ownerEmailError &&
      this.props.ownerEmail.length &&
      !this.props.orderTypeError &&
      this.props.orderType.length &&
      !this.props.authorizationError &&
      this.props.authorization &&
      !this.props.disclaimerError &&
      this.props.disclaimer &&
      !this.props.authorizedRepError &&
      this.props.authorizedRep.length
    ) {
      return false;
    } else {
      return true;
    }
  };

  render() {
    const {
      petName,
      petType,
      petBreed,
      petWeight,
      ownerName,
      ownerAddress,
      ownerPhone,
      ownerEmail,
      orderType,
      authorization,
      disclaimer,
      authorizedRep,
      handleChange,
      handleCheckboxChange,
      petNameError,
      petTypeError,
      petWeightError,
      ownerNameError,
      ownerAddressError,
      ownerPhoneError,
      ownerEmailError,
      orderTypeError,
      authorizationError,
      disclaimerError,
      authorizedRepError,
    } = this.props;

    return (
      <>
        <Row form>
          <Col md={12}>
            <h6>Pet and Owner Information:</h6>
          </Col>
        </Row>
        <Row form>
          <Col md={3}>
            <FormGroup>
              <Label htmlFor="petName" hidden>
                Pet's Name
              </Label>
              <Input
                style={{ borderColor: petNameError ? "red" : "" }}
                type="text"
                id="petName"
                name="petName"
                value={petName}
                placeholder="Pet Name"
                onChange={handleChange("petName")}
              />
              {petNameError && (
                <span
                  style={{
                    position: "absolute",
                    fontSize: 12,
                    color: "red",
                  }}
                >
                  * Pet name required
                </span>
              )}
            </FormGroup>
          </Col>
          <Col md={3}>
            <FormGroup>
              <Label htmlFor="petType" hidden>
                Pet Type
              </Label>
              <Input
                style={{ borderColor: petTypeError ? "red" : "" }}
                type="text"
                id="petType"
                name="petType"
                value={petType}
                placeholder="Pet Type"
                onChange={handleChange("petType")}
              />
              {petTypeError && (
                <span
                  style={{
                    position: "absolute",
                    fontSize: 12,
                    color: "red",
                  }}
                >
                  * Pet type required
                </span>
              )}
            </FormGroup>
          </Col>
          <Col md={3}>
            <FormGroup>
              <Label htmlFor="petBreed" hidden>
                Breed
              </Label>
              <Input
                type="text"
                id="petBreed"
                name="petBreed"
                value={petBreed}
                placeholder="Breed"
                onChange={handleChange("petBreed")}
              />
            </FormGroup>
          </Col>
          <Col md={3}>
            <FormGroup>
              <Label htmlFor="petWeight" hidden>
                Weight
              </Label>
              <Input
                style={{ borderColor: petWeightError ? "red" : "" }}
                type="number"
                id="petWeight"
                name="petWeight"
                value={petWeight}
                placeholder="Approx Weight"
                onChange={handleChange("petWeight")}
              />
              {petWeightError && (
                <span
                  style={{
                    position: "absolute",
                    fontSize: 12,
                    color: "red",
                  }}
                >
                  * Pet weight required
                </span>
              )}
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col md={4}>
            <FormGroup>
              <Label htmlFor="ownerName" hidden>
                Owner Name
              </Label>
              <Input
                style={{ borderColor: ownerNameError ? "red" : "" }}
                type="text"
                id="ownerName"
                name="ownerName"
                value={ownerName}
                placeholder="Owner Name"
                onChange={handleChange("ownerName")}
              />
              {ownerNameError && (
                <span
                  style={{
                    position: "absolute",
                    fontSize: 12,
                    color: "red",
                  }}
                >
                  * Owner name required
                </span>
              )}
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup>
              <Label htmlFor="ownerPhone" hidden>
                Phone
              </Label>
              <Input
                style={{ borderColor: ownerPhoneError ? "red" : "" }}
                type="tel"
                id="ownerPhone"
                name="ownerPhone"
                value={ownerPhone}
                placeholder="Owner Phone"
                onChange={handleChange("ownerPhone")}
              />
              {ownerPhoneError && (
                <span
                  style={{
                    position: "absolute",
                    fontSize: 12,
                    color: "red",
                  }}
                >
                  * Owner phone number required
                </span>
              )}
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup>
              <Label htmlFor="ownerEmail" hidden>
                Email
              </Label>
              <Input
                style={{ borderColor: ownerEmailError ? "red" : "" }}
                type="email"
                id="ownerEmail"
                name="ownerEmail"
                value={ownerEmail}
                placeholder="Owner Email"
                onChange={handleChange("ownerEmail")}
              />
              {ownerEmailError && (
                <span
                  style={{
                    position: "absolute",
                    fontSize: 12,
                    color: "red",
                  }}
                >
                  * Owner email address required
                </span>
              )}
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col md={10}>
            <FormGroup>
              <Label htmlFor="ownerAddress" hidden>
                Address
              </Label>
              <Input
                style={{ borderColor: ownerAddressError ? "red" : "" }}
                type="text"
                id="ownerAddress"
                name="ownerAddress"
                value={ownerAddress}
                placeholder="Owner Full Address - street, city, state, zip"
                onChange={handleChange("ownerAddress")}
              />
              {ownerAddressError && (
                <span
                  style={{
                    position: "absolute",
                    fontSize: 12,
                    color: "red",
                  }}
                >
                  * Owner street address required
                </span>
              )}
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col md={3}>
            <FormGroup>
              <Label for="orderType">Cremation Type</Label>
              <select
                style={{ borderColor: orderTypeError ? "red" : "" }}
                type="select"
                value={orderType}
                name="orderType"
                id="orderType"
                className="form-control"
                onChange={handleChange("orderType")}
              >
                <option defaultValue>Select...</option>
                <option>Private</option>
                <option>Communal (no return)</option>
              </select>
              {orderTypeError && (
                <span
                  style={{
                    position: "absolute",
                    fontSize: 12,
                    color: "red",
                  }}
                >
                  * Order type required
                </span>
              )}
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="mb-4">
            <div className="form-scroller form-scroller-outer">
              <div className="form-scroller-inner">
                <h6>Cremation Authorization:</h6>
                <p>
                  <small>
                    The Owner hereby authorizes Legacy Pets Of Charleston to
                    arrange the cremation of the remains of the Pet using the
                    services of the Crematory. In providing this authorization,
                    the undersigned represents that he or she is the Owner or
                    the legal representative of the Owner and has the full right
                    and authority to arrange the cremation and the disposition
                    of the cremated remains. The undersigned understands that
                    Cremation is an irreversible and final process.
                  </small>
                </p>
                <h6>Cremation Process:</h6>
                <p>
                  <small>
                    The undersigned acknowledges that due to the nature of the
                    cremation process, any material on the remains of the Pet,
                    such as collars, tags, etc., will be destroyed if not
                    removed. Accordingly, the undersigned has removed any such
                    material or, if the material is present on the Pet’s
                    remains, the undersigned understands it will either be
                    destroyed or removed and disposed of by the Crematory.
                  </small>
                </p>
                <h6>Certification:</h6>
                <p>
                  <small>
                    The undersigned certifies the accuracy of all information on
                    this Authorization and will indemnify and hold harmless
                    Legacy Pets Of Charleston, their owners, employer and
                    agents, from any liability, cost, expenses or claims
                    resulting from this Authorization and release thereon.
                  </small>
                </p>
              </div>
            </div>
          </Col>
        </Row>
        <Row form>
          <Col md={6}>
            <FormGroup>
              <CustomInput
                type="checkbox"
                id="disclaimer"
                name="disclaimer"
                label="I have read and agree to the above disclaimers."
                value={disclaimer}
                onChange={(event) => {
                  this.setState({
                    disclaimerError: !event.target.checked,
                  });
                  handleCheckboxChange(event);
                }}
              />
              {disclaimerError && (
                <span
                  style={{
                    // position: "absolute",
                    fontSize: 12,
                    color: "red",
                  }}
                >
                  * Disclaimer agreeance required
                </span>
              )}
              <CustomInput
                type="checkbox"
                id="authorization"
                name="authorization"
                label="I authorization the cremation of the remains."
                value={authorization}
                onChange={(event) => {
                  this.setState({
                    authorizationError: !event.target.checked,
                  });
                  handleCheckboxChange(event);
                }}
              />
              {authorizationError && (
                <span
                  style={{
                    fontSize: 12,
                    color: "red",
                  }}
                >
                  * Authorization agreeance required
                </span>
              )}
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col md={4}>
            <FormGroup>
              <Label htmlFor="authorizedRep" hidden>
                Authorized Rep
              </Label>
              <Input
                style={{ borderColor: authorizedRepError ? "red" : "" }}
                type="text"
                id="authorizedRep"
                name="authorizedRep"
                value={authorizedRep}
                placeholder="Authorized Name or Owner Name"
                onChange={handleChange("authorizedRep")}
              />
              {authorizedRepError && (
                <span
                  style={{
                    position: "absolute",
                    fontSize: 12,
                    color: "red",
                  }}
                >
                  * Authorization required
                </span>
              )}
            </FormGroup>
          </Col>
        </Row>
        <Row form className="mt-3">
          <Col md={6}>
            <Link className="btn btn-outline-danger mr-3" to="/">
              Cancel Order
            </Link>
          </Col>
          <Col md={6} className="text-right">
            <Button
              className="Next btn btn-success"
              onClick={this.continue}
              disabled={this._formValidated()}
            >
              Next »
            </Button>
          </Col>
        </Row>
      </>
    );
  }
}

export default CreateOrderOne;
