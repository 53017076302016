//React
import React, { Component } from "react";

//Packages
import { connect } from "react-redux";

//Components
import AppRouter from "./Router/Router";
import Loader from "./components/layouts/Loader";

//Redux Actions
import { checkUserAuth } from "./store/actions/Auth";

class App extends Component {
  constructor(props) {
    super(props);

    //Running string of Sagas to determine if user is authenticated and grabbing their data
    this.props.checkUserAuth();
  }

  render() {
    const { authUser, userOrders, history, initializing } = this.props;
    if (initializing) return <Loader />;
    return (
      <AppRouter history={history} authUser={authUser} orders={userOrders} />
    );
  }
}

const mapStateToProps = ({ auth, orders }) => {
  const { authUser, initializing } = auth;
  const { userOrders } = orders;
  return { authUser, userOrders, initializing };
};

export default connect(mapStateToProps, { checkUserAuth })(App);
