import React, { Component } from "react";
import { Col, Row, Button, FormGroup, Label, Input } from "reactstrap";

class CreateOrderTwo extends Component {
  continue = (e) => {
    e.preventDefault();
    this.props.nextStep();
  };

  back = (e) => {
    e.preventDefault();
    this.props.prevStep();
  };

  _formValidated = () => {
    if (this.props.pawPrint) {
      if (
        this.props.pawPrint === "Yes" &&
        this.props.pawPrintQty.length &&
        !this.props.pawPrintQtyError &&
        this.props.urnType.length &&
        !this.props.urnTypeError &&
        this.props.urnSize.length &&
        !this.props.urnSizeError
      ) {
        return false;
      } else if (this.props.pawPrint === "No") {
        return false;
      } else {
        return true;
      }
    }
    return true;
  };

  render() {
    const {
      pawPrint,
      pawPrintQty,
      urnType,
      urnSize,
      comment,
      pawPrintError,
      pawPrintQtyError,
      urnTypeError,
      urnSizeError,
      handleChange,
    } = this.props;
    return (
      <>
        <Row form>
          <Col md={12}>
            <h6>Special Instructions:</h6>
          </Col>
        </Row>
        <Row form className="form-row mb-3 px-2 pt-3 rounded bg-grey">
          <Col md={2}>
            <FormGroup>
              <Label for="urnType">Paw Print:</Label>
              <select
                type="select"
                value={pawPrint}
                name="pawPrint"
                id="pawPrint"
                className="form-control"
                onChange={handleChange("pawPrint")}
              >
                <option defaultValue>Select...</option>
                <option>Yes</option>
                <option>No</option>
              </select>
              {pawPrintError && (
                <span
                  style={{
                    position: "absolute",
                    fontSize: 12,
                    color: "red",
                  }}
                >
                  * Paw print selection required
                </span>
              )}
            </FormGroup>
          </Col>
          <Col md={1}>
            <FormGroup>
              <Label for="pawPrintQty">Qty:</Label>
              <Input
                type="number"
                name="pawPrintQty"
                value={pawPrintQty}
                onChange={handleChange("pawPrintQty")}
              />
              {pawPrint === "Yes" && pawPrintQtyError && (
                <span
                  style={{
                    position: "absolute",
                    fontSize: 12,
                    color: "red",
                  }}
                >
                  * Paw print quantity required
                </span>
              )}
            </FormGroup>
          </Col>
          <Col md={2}>
            <FormGroup>
              <Label for="urnType">Type of Urn:</Label>
              <select
                type="select"
                value={urnType}
                name="urnType"
                id="urnType"
                className="form-control"
                onChange={handleChange("urnType")}
              >
                <option defaultValue>Select...</option>
                <option>Standard</option>
                <option>Specialty Rosewood</option>
              </select>
              {pawPrint === "Yes" && urnTypeError && (
                <span
                  style={{
                    position: "absolute",
                    fontSize: 12,
                    color: "red",
                  }}
                >
                  * Urn type required
                </span>
              )}
            </FormGroup>
          </Col>
          <Col md={2}>
            <FormGroup>
              <Label for="urnSize">Size of Urn:</Label>
              <select
                type="select"
                value={urnSize}
                name="urnSize"
                id="urnSize"
                className="form-control"
                onChange={handleChange("urnSize")}
              >
                <option defaultValue>Select...</option>
                <option>Small</option>
                <option>Medium</option>
                <option>Large</option>
              </select>
              {pawPrint === "Yes" && urnSizeError && (
                <span
                  style={{
                    position: "absolute",
                    fontSize: 12,
                    color: "red",
                  }}
                >
                  * Urn size required
                </span>
              )}
            </FormGroup>
          </Col>
        </Row>
        <Row form className="mb-3">
          <Col md={12}>
            <FormGroup>
              <Label for="comment">Additional Comments:</Label>
              <Input
                type="textarea"
                id="comment"
                name="comment"
                value={comment}
                onChange={handleChange("comment")}
                placeholder="Comments"
              />
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col md={12} className="text-right">
            <Button
              className="btn btn-outline-secondary mr-3"
              onClick={this.back}
            >
              « Back
            </Button>
            <Button
              className="Next btn btn-success"
              onClick={this.continue}
              disabled={this._formValidated()}
            >
              Next »
            </Button>
          </Col>
        </Row>
      </>
    );
  }
}

export default CreateOrderTwo;
