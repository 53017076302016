import {
  all,
  call,
  select,
  fork,
  put,
  takeEvery,
  take,
  cancelled,
  race,
} from "redux-saga/effects";

import { companyUsersCollectionWatch } from "./Users";

import {
  CREATE_ORDER,
  GETTING_ORDERS,
  SIGNOUT_USER,
  UPDATE_ORDER_STATUS,
} from "../actions/types";

import { eventChannel } from "redux-saga";

import { db } from "../../config/fbConfig";

import { signUserInSuccess } from "../actions/Auth";

import * as selectors from "./Selectors";

const orders = db.collection("orders");

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////// Get User Orders //////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export function* ordersCollectionWatch(userCollectionData, user) {
  if (!userCollectionData.makeAdmin) {
    const ordersCollectionChannel = eventChannel((emit) => {
      const unsubscribeOrdersCollectionData = orders
        .where("submittedById", "==", user.uid)
        .orderBy("createdAt", "desc")
        .onSnapshot(function (querySnapshot) {
          if (querySnapshot) {
            var orders = [];
            querySnapshot.forEach(function (doc) {
              orders.push({ ...doc.data(), id: doc.ref.id });
            });
            emit(orders);
          } else {
            let doc = { exists: false };
            emit({ doc });
          }
        });
      return unsubscribeOrdersCollectionData;
    });
    try {
      while (true) {
        const { userSignOut, ordersCollectionData } = yield race({
          userSignOut: take(SIGNOUT_USER),
          ordersCollectionData: take(ordersCollectionChannel),
        });

        if (userSignOut) {
          ordersCollectionChannel.close();
        } else {
          yield put(
            signUserInSuccess([
              user,
              userCollectionData,
              ordersCollectionData,
              null,
            ])
          );
        }
      }
    } finally {
      if (yield cancelled()) ordersCollectionChannel.close();
    }
  } else {
    const ordersCollectionChannel = eventChannel((emit) => {
      const unsubscribeOrdersCollectionData = orders
        .orderBy("createdAt", "desc")
        .onSnapshot(function (querySnapshot) {
          if (querySnapshot) {
            var orders = [];
            querySnapshot.forEach(function (doc) {
              orders.push({ ...doc.data(), id: doc.ref.id });
            });
            emit(orders);
          } else {
            let doc = { exists: false };
            emit({ doc });
          }
        });
      return unsubscribeOrdersCollectionData;
    });
    try {
      while (true) {
        const { userSignOut, ordersCollectionData } = yield race({
          userSignOut: take(SIGNOUT_USER),
          ordersCollectionData: take(ordersCollectionChannel),
        });

        if (userSignOut) {
          ordersCollectionChannel.close();
        } else {
          yield fork(
            companyUsersCollectionWatch,
            user,
            userCollectionData,
            ordersCollectionData
          );
        }
      }
    } catch (error) {
    } finally {
      if (yield cancelled()) ordersCollectionChannel.close();
    }
  }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////// Create New Orders ////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const createOrderRequest = (order, profile, id) => {
  return orders
    .add({
      ...order,
      submittedBy: profile.companyName,
      submittedById: id,
      status: "Submitted",
      createdAt: new Date(),
    })
    .then((docRef) => {
      return { createdOrderId: docRef.id };
    })
    .catch((error) => {
      return error;
    });
};

export function* createOrder({ payload }) {
  const order = payload;
  const profile = yield select(selectors._profile);
  const authUser = yield select(selectors._authUser);
  const { createdOrderId, error } = yield call(() =>
    createOrderRequest(order, profile, authUser.uid)
  );
  if (createdOrderId) {
    console.log(createdOrderId);
  } else {
    //TODO: Error Handling...show user an alert and such
    console.log(error);
  }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////// Update Order Status //////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const updateOrderStatusRequest = (status, id) => {
  return orders
    .doc(id)
    .update({
      status,
    })
    .then(() => {
      return true;
    })
    .catch((error) => {
      return error;
    });
};

export function* updateOrderStatus({ payload }) {
  const { status, id } = payload;
  const { updatedOrder, error } = yield call(() =>
    updateOrderStatusRequest(status, id)
  );
  if (updatedOrder) {
    //TODO: Need anything here??
  } else {
    //TODO: Error Handling...show user an alert and such
    console.log(error);
  }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////// Generators For Root Saga ///////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export function* creatingUserOrder() {
  yield takeEvery(CREATE_ORDER, createOrder);
}

export function* gettingUserOrders() {
  yield takeEvery(GETTING_ORDERS, ordersCollectionWatch);
}

export function* updatingOrderStatus() {
  yield takeEvery(UPDATE_ORDER_STATUS, updateOrderStatus);
}

export default function* rootSaga() {
  yield all([
    fork(gettingUserOrders),
    fork(creatingUserOrder),
    fork(updatingOrderStatus),
  ]);
}
