import { CREATE_ORDER, UPDATE_ORDER_STATUS } from "./types";

export const creatingOrder = (payload) => {
  return {
    type: CREATE_ORDER,
    payload: payload,
  };
};

export const updateOrderStatus = (data) => {
  return {
    type: UPDATE_ORDER_STATUS,
    payload: data,
  };
};
