//React, React-Native
import React from "react";

//Components
import Dashboard from "../components/dashboard/Dashboard";
import SignIn from "../components/auth/SignIn";
import CreateUser from "../components/auth/CreateUser";
import CreateOrder from "../components/orders/CreateOrder";
import UserDashboard from "../components/users/UserDashboard";
import UserDetails from "../components/users/UserDetails";
import OrderDetails from "../components/orders/OrderDetails";
import Navbar from "../components/layouts/Navbar";

//Router
import { BrowserRouter as Router, Redirect, Route } from "react-router-dom";
import * as routes from "./constants/routes";

const RestrictedRoute = ({ component: Component, authUser, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      authUser ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: routes.SIGNIN,
            state: { from: props.location },
          }}
        />
      )
    }
  />
);

//PrevAuthedRoute Components makes sure a user who is signed in/authenticated cannot signin again or signup again...if they try and access the signin route or signup route they will be redirected to the their Dashboard
const PrevAuthedRoute = ({ component: Component, authUser, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      !authUser ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: routes.DASHBOARD,
            state: { from: props.location },
          }}
        />
      )
    }
  />
);

const AppRouter = ({ authUser, userOrders, match, history }) => {
  return (
    <div className="App">
      <Router style={{ width: "100%", height: "100%" }}>
        {authUser && <Navbar />}
        <main role="main" className="container">
          {!authUser && <Redirect to={routes.SIGNIN} />}
          <RestrictedRoute
            exact
            path={routes.DASHBOARD}
            authUser={authUser}
            orders={userOrders}
            match={match}
            history={history}
            component={Dashboard}
          />
          <PrevAuthedRoute
            path={routes.SIGNIN}
            authUser={authUser}
            match={match}
            history={history}
            component={SignIn}
          />
          <RestrictedRoute
            path={routes.CREATE_USER}
            authUser={authUser}
            orders={userOrders}
            match={match}
            history={history}
            component={CreateUser}
          />
          <RestrictedRoute
            path={routes.CREATE_ORDER}
            authUser={authUser}
            orders={userOrders}
            match={match}
            history={history}
            component={CreateOrder}
          />
          <RestrictedRoute
            path={routes.USERS}
            authUser={authUser}
            orders={userOrders}
            match={match}
            history={history}
            component={UserDashboard}
          />
          <RestrictedRoute
            path={routes.USER_DETAILS}
            authUser={authUser}
            orders={userOrders}
            match={match}
            history={history}
            component={UserDetails}
          />
          <RestrictedRoute
            path={routes.ORDER_DETAILS}
            authUser={authUser}
            orders={userOrders}
            match={match}
            history={history}
            component={OrderDetails}
          />
        </main>
      </Router>
    </div>
  );
};

export default AppRouter;
