import React, { Component } from "react";
import { connect } from "react-redux";
import logo from "../../img/legacy-logo.png";
import { signUserIn } from "../../store/actions/Auth";
import { withRouter } from "react-router-dom";

class SignIn extends Component {
  state = {
    email: "",
    password: "",
  };

  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
    });
  };
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.signUserIn(this.state);
  };
  render() {
    const { authError } = this.props;
    return (
      <div className="text-center mt-5">
        <img
          src={logo}
          className="form-signin"
          alt="Legacy Pets of Charleston"
        />
        <form onSubmit={this.handleSubmit} className="form-signin">
          <label htmlFor="email" className="sr-only">
            Email address
          </label>
          <input
            onChange={this.handleChange}
            type="email"
            id="email"
            className="form-control"
            placeholder="Email address"
          ></input>
          <label htmlFor="password" className="sr-only">
            Password
          </label>
          <input
            onChange={this.handleChange}
            type="password"
            id="password"
            className="form-control"
            placeholder="Password"
          ></input>
          <button className="btn btn-lg btn-legacy btn-block">Sign in</button>
          <div className="text-danger">
            {authError ? <p>{authError}</p> : null}
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = ({ auth }) => {
  const { authError } = auth;
  return {
    authError,
  };
};

export default withRouter(connect(mapStateToProps, { signUserIn })(SignIn));
