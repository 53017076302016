export const validateEmail = (email) => {
  // RFC 5322 Official Standard for email validation not including Internationalized Email Addresses
  const regExEmail = new RegExp(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
  console.log(!regExEmail.test(email.trim()));
  return !regExEmail.test(email.trim());
};

export const validatePhoneNumber = (number) => {
  const phoneRegEx = /^(?!.*(.)\1{6})(.{8,})([+][0-9]{1,3}([ .-])?)?([(]{1}[0-9]{3}[)])?([0-9A-Z .-]{1,32})((x|ext|extension)?[0-9]{1,4}?)$/;
  return !phoneRegEx.test(number.trim());
};

export const validateEmpty = (text) => {
  const trimmedText = text.trim();
  return !!!trimmedText;
};

export const validateSelect = (text) => {
  if (text !== "Select...") {
    return false;
  } else {
    return true;
  }
};

export const validateWeight = (value) => {
  if (value > 0) {
    return false;
  } else {
    return true;
  }
};
