import {
  SIGNIN_USER,
  SIGNIN_USER_SUCCESS,
  SIGNIN_USER_FAILURE,
  CHECK_USER_AUTHENTICATED,
  USER_AUTHENTICATED_FAILURE,
  SIGNOUT_USER,
  SIGNOUT_USER_SUCCESS,
} from "./types";

export const signUserIn = (payload) => {
  return {
    type: SIGNIN_USER,
    payload: payload,
  };
};

export const signUserInSuccess = (payload) => {
  return {
    type: SIGNIN_USER_SUCCESS,
    payload: payload,
  };
};

export const signUserInFailure = (payload) => {
  return {
    type: SIGNIN_USER_FAILURE,
    payload: payload,
  };
};

export const checkUserAuth = () => {
  return {
    type: CHECK_USER_AUTHENTICATED,
  };
};

export const userNotAuthenticated = () => {
  return {
    type: USER_AUTHENTICATED_FAILURE,
  };
};

export const userSignOut = () => {
  return {
    type: SIGNOUT_USER,
  };
};

export const userSignOutSuccess = () => {
  return {
    type: SIGNOUT_USER_SUCCESS,
  };
};
