import React, { Component } from 'react'
import { Col, Row, Button } from 'reactstrap'

class CreateOrderThree extends Component {
    back = e => {
        e.preventDefault()
        this.props.prevStep()
    }
    render(){
        const { 
          petName, 
          petType, 
          petBreed, 
          petWeight, 
          ownerName, 
          ownerAddress, 
          ownerPhone, 
          ownerEmail,
          orderType,
          authorizedRep, 
          pawPrint,
          pawPrintQty,
          urnType,
          urnSize,
          comment } = this.props
        return(
            <>
                <Row form>
                    <Col md={12}>
                        <h6>Please confirm the order details:</h6>
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                    Pet Name: <b>{petName}</b><br />
                    Pet Type: <b>{petType}</b><br />
                    Pet Breed: <b>{petBreed}</b><br />
                    Pet Weight: <b>{petWeight}</b><br />
                    Owner Name: <b>{ownerName}</b><br />
                    Owner Address: <b>{ownerAddress}</b><br />
                    Owner Phone: <b>{ownerPhone}</b><br />
                    Owner Email: <b>{ownerEmail}</b><br />
                    Order Type: <b>{orderType}</b><br />
                    Authorized Rep or Owner: <b>{authorizedRep}</b><br />
                    Paw Print: <b>{pawPrint}</b><br />
                    Paw Print Qty: <b>{pawPrintQty}</b><br />
                    Urn Type: <b>{urnType}</b><br />
                    Urn Size: <b>{urnSize}</b><br />
                    Comments: <b>{comment}</b><br />
                    </Col>
                </Row>
                <Row form className='mt-3'>
                    <Col md={12} className='text-right'>
                        <Button className="btn btn-outline-secondary mr-3" onClick={this.back}>
                            « Back
                        </Button>
                        <Button className="Submit" type='submit'>
                            Submit
                        </Button>
                    </Col>
                </Row>
            </>
        )
    }
}

export default CreateOrderThree