import React from 'react'

const ClientInfo = (props) => {
  return (
    <div className='container'>
      <div className='row align-items-center justify-content-between p-3 my-3 bg-white rounded box-shadow'>
        <div className='col-sm-12 col-md-6'>
          <div className='row'>
          { props.profile.companyLogo ? 
            <div id='client-logo' className='col-md-4'>
              <img src={props.profile.companyLogo} alt={props.profile.companyName} />
            </div> : 
            <div className="lh-100 text-muted col-8">
              <h6 className="mb-1 lh-200">{props.profile.companyName}</h6>
            </div>
          }
          </div>
        </div>
        <div className="lh-200 col-sm-12 col-md-6 mt-3 mt-md-0 text-md-right">
          <p className="mb-0 lh-200">{props.profile.companyAddress1} {props.profile.companyAddress2}</p>
          <p className="mb-0 lh-200">
          {props.profile.companyCity}, {props.profile.companyState} {props.profile.companyZip}
          </p>
          <p className="mb-0 lh-200">{props.profile.companyPhone}</p>
        </div>
      </div>
    </div>
  )
}

export default ClientInfo