import {
  all,
  //call,
  //select,
  fork,
  put,
  takeEvery,
  take,
  cancelled,
  race,
} from "redux-saga/effects";

import { GETTING_USERS, SIGNOUT_USER } from "../actions/types";

import { eventChannel } from "redux-saga";

import { db } from "../../config/fbConfig";

import { signUserInSuccess } from "../actions/Auth";

const users = db.collection("users");

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////// Get User Orders //////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export function* companyUsersCollectionWatch(
  user,
  userCollectionData,
  ordersCollectionData
) {
  const companyUsersCollectionChannel = eventChannel((emit) => {
    const unsubscribeCompanyUserCollectionData = users
      .orderBy("displayName", "asc")
      .onSnapshot(function (querySnapshot) {
        if (querySnapshot) {
          var users = [];
          querySnapshot.forEach(function (doc) {
            users.push({ ...doc.data(), id: doc.ref.id });
          });
          emit(users);
        } else {
          let doc = { exists: false };
          emit({ doc });
        }
      });
    return unsubscribeCompanyUserCollectionData;
  });
  try {
    while (true) {
      const { userSignOut, companyUsersCollectionData } = yield race({
        userSignOut: take(SIGNOUT_USER),
        companyUsersCollectionData: take(companyUsersCollectionChannel),
      });

      if (userSignOut) {
        companyUsersCollectionChannel.close();
      } else {
        yield put(
          signUserInSuccess([
            user,
            userCollectionData,
            ordersCollectionData,
            companyUsersCollectionData,
          ])
        );
      }
    }
  } catch (error) {
  } finally {
    if (yield cancelled()) companyUsersCollectionChannel.close();
  }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////// Create New User ////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// const createUserRequest = () => {

// };

// export function* createUser({ payload }) {
// }

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////// Generators For Root Saga ///////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// export function* creatingUserOrder() {
//   yield takeEvery(CREATE_ORDER, createOrder);
// }

export function* gettingCompanyUsers() {
  yield takeEvery(GETTING_USERS, companyUsersCollectionWatch);
}

export default function* rootSaga() {
  yield all([fork(gettingCompanyUsers)]);
}
