////////////////////// Auth ///////////////////////
export const SIGNIN_USER = "signin_user";
export const SIGNIN_USER_SUCCESS = "signin_user_success";
export const SIGNIN_USER_FAILURE = "signin_user_failure";
export const GETTING_USER_PROFILE = "getting_user_profile";
export const CHECK_USER_AUTHENTICATED = "check_user_authenticated";
export const USER_AUTHENTICATED_SUCCESS = "user_authenticated_success";
export const USER_AUTHENTICATED_FAILURE = "user_Authenticated_failure";
export const SIGNOUT_USER = "signout_user";
export const SIGNOUT_USER_SUCCESS = "signout_user_success";

////////////////////// Orders /////////////////////
export const GETTING_ORDERS = "getting_orders";
export const CREATE_ORDER = "create_order";
export const UPDATE_ORDER_STATUS = "update_order_status";

////////////////////// Users /////////////////////
export const GETTING_USERS = "getting_users";
