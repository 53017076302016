import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import SignedInLinks from "./SignedInLinks";
import SignedOutLinks from "./SignedOutLinks";

const Navbar = (props) => {
  const { authUser, profile } = props;
  const links = authUser ? (
    <SignedInLinks profile={profile} />
  ) : (
    <SignedOutLinks />
  );

  return (
    <nav className={`navbar mb-4`}>
      <Link to="/" className="navbar-brand">
        Legacy Pets of Charleston
      </Link>
      {links}
    </nav>
  );
};

const mapStateToProps = ({ auth }) => {
  const { authUser, profile } = auth;
  return {
    authUser,
    profile,
  };
};

export default connect(mapStateToProps)(Navbar);
