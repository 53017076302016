import React, { Component } from "react"
import ClientInfo from "./ClientInfo";
import OrdersList from "../orders/OrdersList"
import { connect } from "react-redux"
import Loader from "../layouts/Loader"

class Dashboard extends Component {
  render() {
    const { userOrders, profile, loading } = this.props
    if (loading) return <Loader />
    if (!userOrders.length) return <span>No Orders</span>

    return (
      <div className="dashboard">
      {profile.makeAdmin ? 
      '' :
      <ClientInfo profile={profile} />
      }
        <OrdersList orders={userOrders} />
      </div>
    );
  }
}

const mapStateToProps = ({ auth, orders }) => {
  const { authUser, profile, loading } = auth
  const { userOrders } = orders
  return {
    authUser,
    profile,
    loading,
    userOrders,
  }
}

export default connect(mapStateToProps)(Dashboard)
