import React from "react";

const UserSummary = ({ user }) => {
  return (
    <div className="container text-muted pt-3">
      <div className="row pb-3 mb-0 border-bottom border-gray">
        <div className="col-12 col-md-4 mb-4 mb-md-0">
          <strong className="d-block text-gray-dark">{user.displayName}</strong>
        </div>
      </div>
    </div>
  );
};

export default UserSummary;
