import React from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { userSignOut } from "../../store/actions/Auth";

const SignedInLinks = (props) => {
  return (
    <div className="d-flex navbar-actions justify-content-end align-items-center">
      {props.profile.makeAdmin === true && (
        <ul className="navbar-nav">
          <li className="nav-item mr-4">
            <NavLink to="/">Dashboard</NavLink>
          </li>
          <li className="nav-item mr-4">
            <NavLink to="/users">Users</NavLink>
          </li>
        </ul>
      )}
      <button
        type="button"
        onClick={() => props.userSignOut()}
        className="btn btn-sm btn-light"
      >
        Log Out
      </button>
    </div>
  );
};

export default connect(null, { userSignOut })(SignedInLinks);
